<template>
  <section class="look">
    <div class="container">
      <div class="look__title">
        <h1>{{ $t('cases_page.lobbyx.title') }}</h1>
      </div>
      <div class="look__text">{{ $t('cases_page.lobbyx.desc') }}</div>
    </div>
  </section>

  <section class="presentation">
    <picture>
      <source
          srcset="../assets/presentation_lobbyx.webp"
          type="image/webp"
      >
      <img
          class="presentation__image"
          src="../assets/presentation_lobbyx.png"
          alt=""
          loading="lazy"
      />
    </picture>
  </section>

  <section class="about">
    <div class="container">
      <div class="about__inner">
        <div class="about__tech">

          <div class="about__tech-item">
            <div class="about__tech-desc">
              {{ $t('cases_page.lobbyx.category') }}
            </div>

            <span>{{ $t('cases_page.lobbyx.category_list') }}</span>
          </div>


          <div class="about__tech-item">
            <div class="about__tech-desc">
              {{ $t('cases_page.lobbyx.services') }}
            </div>

            <span>{{ $t('cases_page.lobbyx.services_list') }}</span>
          </div>
          <div class="about__tech-item">
            <div class="about__tech-desc">
              {{ $t('cases_page.lobbyx.about_tech') }}
            </div>

            <span>{{ $t('cases_page.lobbyx.about_tech_list') }}</span>
          </div>
        </div>

        <div class="about__info">
          <div>
            <h2 class="about__info-title">{{ $t('cases_page.lobbyx.about_info') }}</h2>
          </div>
          <div class="about__info-text">
            {{ $t('cases_page.lobbyx.about_info_desc') }}
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="goal">
    <div class="container">
      <div class="goal__inner">
        <div class="goal__title">
          <h2>{{ $t('cases_page.lobbyx.goal_title') }}</h2>
        </div>

        <div class="goal__main">
          <div class="goal__main-text">{{ $t('cases_page.lobbyx.goal_title_desc') }}</div>

          <span>{{ $t('cases_page.lobbyx.goal_title_list') }}</span>

          <ul>
            <li>{{ $t('cases_page.lobbyx.goal_title_list_1') }}</li>
            <li>{{ $t('cases_page.lobbyx.goal_title_list_2') }}</li>
            <li>{{ $t('cases_page.lobbyx.goal_title_list_3') }}</li>
            <li>{{ $t('cases_page.lobbyx.goal_title_list_4') }}</li>
            <li>{{ $t('cases_page.lobbyx.goal_title_list_5') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="features">
    <div class="container">
      <div class="features__inner">
        <div class="features__info">
          <div class="features__info-wrapper">
            <div>
              <h2 class="features__info-title">{{ $t('cases_page.lobbyx.features-title') }}</h2>
            </div>
            <div class="features__info-text">{{ $t('cases_page.lobbyx.features-desc_1') }}</div>
            <div class="features__info-text">{{ $t('cases_page.lobbyx.features-desc_2') }}</div>


            <span>{{ $t('cases_page.lobbyx.features-list') }}</span>

            <ul>
              <li>{{ $t('cases_page.lobbyx.features-list_1') }}</li>
              <li>{{ $t('cases_page.lobbyx.features-list_2') }}</li>
              <li>{{ $t('cases_page.lobbyx.features-list_3') }}</li>
              <li>{{ $t('cases_page.lobbyx.features-list_4') }}</li>
              <li>{{ $t('cases_page.lobbyx.features-list_5') }}</li>
            </ul>
          </div>
        </div>

        <div class="features__site">
          <picture>
            <source
                srcset="../assets/presentation_site_lobbyx.webp"
                type="image/webp"
            >
            <img
                src="../assets/presentation_site_lobbyx.png"
                alt=""
                loading="lazy"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>

  <section class="mobile">
    <div class="container">
      <div class="mobile__title">
        <div class="mobile__title-main">
          <h2>{{ $t('cases_page.lobbyx.mobile_title') }}</h2>
        </div>
        <div class="mobile__title-label">
          <div class="mobile__title-text">{{ $t('cases_page.lobbyx.mobile_desc_1') }}</div>
        </div>

      </div>
    </div>

    <div class="mobile__body">
      <div class="container">
        <div class="mobile__inner-image">
          <img
              src="../assets/lobby_x_case_general_base.gif"
              alt=""
              loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>


  <section class="tablet">
    <div class="container">
      <div class="tablet__title">
        <div class="tablet__title-main">
          <h2>{{ $t('cases_page.lobbyx.tablet_title') }}</h2>
        </div>
        <div class="tablet__title-text">{{ $t('cases_page.lobbyx.table_desc') }}</div>
      </div>
      <div class="tablet__body">
        <div class="tablet__body-image">
            <img
                src="../assets/lobby_x_case_navigation.gif"
                alt=""
                loading="lazy"
            />
        </div>
      </div>
    </div>


  </section>


  <section class="conclusion">
    <div class="container">
      <div>
        <h2 class="conclusion__title">{{ $t('cases_page.lobbyx.conclusion_title') }}</h2>
      </div>

      <div class="conclusion__text">{{ $t('cases_page.lobbyx.conclusion_desc') }}</div>
    </div>
  </section>

  <Contact />

  <section class="view-more">
    <div class="container">
      <RecentProjects/>
    </div>
  </section>
</template>

<script>
import Contact from "../components/Contact_1";
import RecentProjects from "../components/RecentProjects";
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "LobbyX",
  data() {
    return {
      recentCases: [
        {id: 1, name: 'View all cases', path: '/cases/'}
      ],
      isMobile: false
    }
  },
  components: {
    Contact,
    RecentProjects
  },
  setup() {
    const siteData = reactive({
      title: "An automated system for Ukrainian platform Lobby X: case study",
      description: "We created a handy CRM that helps users focus on content. Besides, we kept similarities with Excel spreadsheets, as Lobby X managers get used to them.",
      keywords: "CRM, social enterprise, case",
      robots: "index"
    })

    useHead({
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  methods: {
    handleScroll() {
      let featuresSection = document.querySelector(".features");
      let featuresInfo = featuresSection.querySelector(".features__info-wrapper");

      let rect = featuresSection.getBoundingClientRect();

      if (rect.top < 150) {
        featuresInfo.style.position = "sticky";
        featuresInfo.style.top = "0px";
      } else {
        featuresInfo.style.position = "static";
        featuresInfo.style.top = null;
      }
    },

    checkIsMobile() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw < 576) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted() {
    this.checkIsMobile()

    if (window.matchMedia('(min-width: 992px)').matches) {
      document.addEventListener('scroll', this.handleScroll)
    }
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>

@import "../base/styles/media";

.look{
  margin-top: 193px;

  @media(max-width: 991px){
    margin-top: 135px;
  }

  @media(max-width: 575px){
    margin-top: 96px;
  }
  &__title{
    h1{
      font-weight: bold;
      font-size: 72px;
      line-height: 72px;
      color: #2E3139;

      @media(max-width: 991px){
        font-size: 52px;
        line-height: 58px;
      }

      @media(max-width: 575px){
        font-size: 36px;
        line-height: 48px;
      }
    }
  }

  &__text{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 442px;
    margin-top: 30px;

    @media(max-width: 991px){
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      max-width: 400px;
    }

    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
      max-width: 286px;
    }
  }
}

.presentation{
  position: relative;
  padding-top: 37.85%;
  margin-top: 120px;

  @media(max-width: 991px){
    margin-top: 100px;
  }

  @media(max-width: 575px){
    margin-top: 45px;
    padding-top: 0%;
    height: 175px;
  }

  &__image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about{
  margin-top: 120px;

  @media(max-width: 991px) {
    margin-top: 90px;
  }

  @media(max-width: 991px){
    margin-top: 60px;
  }

  @media(max-width: 575px){
    margin-top: 30px;
  }


  @media(min-width: 992px){
    &__inner{
      display: flex;
      justify-content: space-between;
    }
  }

  &__tech{
    flex: 0 1 502px;

    @media(min-width: 992px){
      padding-top: 30px;
    }

    &-item{
      &:not(:first-child){
        margin-top: 60px;

        @media(max-width: 991px){
          margin-top: 35px;
        }

        @media(max-width: 575px){
          margin-top: 0px;
        }
      }

      @media(max-width: 575px){
        display: flex;
        align-items: center;
      }
    }

    &-desc{
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #2E3139;
      position: relative;

      @media(max-width: 991px){
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 575px){
        font-size: 18px;
        line-height: 24px;
        position: relative;
        &:after{
          content: ':';
          position: absolute;
          top: 0;
          right: -5px;
          display: inline-block;
          font-size: 17px;
          margin-left: -3px;
        }
      }

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
        &:after{
          content: ':';
          position: absolute;
          bottom: 0;
          right: -5px;
          display: inline-block;
          font-size: 13px;
        }
      }
    }

    span{
      display: inline-block;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media (min-width: 576px) {
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
      }

    }
  }

  &__info{
    flex: 0 1 502px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 991px){
      margin-top: 80px;
    }

    @media(max-width: 575px){
      margin-top: 30px;
    }

    &-title{
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }

    &-text{
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px){
        margin-top: 20px;
      }

      @media(max-width: 575px){
        margin-top: 10px;
      }

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-btn{
      margin-top: 60px;

      @media(max-width: 991px){
        margin-top: 45px;
      }

      @media(max-width: 575px){
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.goal{
  margin-top: 253px;
  ul{
    list-style: none;
    margin-top: 35px;
    margin-left: 6px;

    @media(max-width: 991px){
      margin-top: 25px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
    }
  }

  ul > li{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    display: flex;
    align-items: flex-start;

    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
    }
  }

  li::before{
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #2E3139;;
    margin-right: 10px;
    margin-top: 12px;

    @media(max-width: 575px){
      margin-top: 9px;
    }
  }


  @media(max-width: 991px){
    margin-top: 120px;
  }

  @media(max-width: 575px){
    margin-top: 30px;
  }

  @media(min-width: 992px){
    &__inner{
      display: flex;
      justify-content: space-between;
    }
  }

  &__title{
    flex: 0 1 502px;
    h2{
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }
  }

  &__main{
    flex: 0 1 502px;
    padding-top: 20px;

    @media(max-width: 575px){
      padding-top: 10px;
    }

    @media(min-width: 992px){
      margin-left: 100px;
    }
    &-text{
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }

    span{
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.features{
  margin-top: 240px;
  position: relative;

  @media(max-width: 991px){
    margin-top: 120px;
  }

  @media(max-width: 575px){
    margin-top: 30px;
  }

  ul{
    list-style: none;
    margin-top: 35px;
    margin-left: 6px;

    @media(max-width: 991px){
      margin-top: 25px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
    }
  }

  ul > li{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    display: flex;
    align-items: flex-start;

    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
    }
  }

  li::before{
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #2E3139;;
    margin-right: 10px;
    margin-top: 12px;

    @media(max-width: 575px){
      margin-top: 9px;
    }
  }

  @media(min-width: 992px){
    &__inner{
      display: flex;
      justify-content: space-between;
    }
  }


  &__info{
    flex: 0 1 400px;
    &-title{
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }

    &-text{
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px){
        margin-top: 20px;
      }

      @media(max-width: 575px){
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    @media(min-width: 992px){
      &-wrapper{
        padding-top: 150px;
      }
    }

    span{
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }

    ul{
      margin-top: 12px;
    }
  }

  &__site{
    flex: 0 1 606px;

    @media(min-width: 992px){
      margin-left: 100px;
    }

    @media(max-width: 1399px){
      flex: 0 1 560px;
    }

    @media(max-width: 1199px){
      flex: 0 1 470px;
    }

    @media(max-width: 991px){
      margin-top: 60px;
    }

    img{
      width: 100%;
    }
  }
}

.learn-more{
  margin-top: 180px;

  @media(max-width: 991px){
    margin-top: 100px;
  }

  @media(max-width: 575px){
    margin-top: 45px;
  }

  @media(min-width: 992px){
    &__inner{
      display: flex;
      justify-content: space-between;
    }
  }

  &__title{
    flex: 0 1 502px;
    h2{
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }
  }

  &__main{
    flex: 0 1 600px;
  }

  &__text{
    flex: 0 1 502px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 20px;

    @media(max-width: 1199px){
      padding-right: 0;
    }

    @media(min-width: 992px){
      margin-left: 100px;
    }

    @media(max-width: 992px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }

    a{
      font-size: 18px;
      font-weight: bold;
      color: #2E3139;
      transition: 0.4s color;

      @media (any-hover: hover) {
        &:hover{
          color: #688E93;
        }
      }
      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__subtext{
    flex: 0 1 502px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 20px;

    @media(max-width: 1199px){
      padding-right: 0;
    }

    @media(min-width: 992px){
      margin-left: 100px;
    }

    @media(max-width: 992px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.changes{
  margin-top: 128px;
  background-color: #253746;
  padding: 120px 0px;

  @media(max-width: 991px){
    padding: 90px 0px;
    margin-top: 90px;
  }

  @media(max-width: 575px){
    padding: 60px 50px;
    margin-top: 60px;
  }

  @media(min-width: 768px){
    &__inner{
      display: flex;
      justify-content: space-between;
    }
  }

  @media(min-width: 992px){
    &__inner{
      padding-right: 60px;
    }
  }

  &__item{
    flex: 0 1 502px;
    &:not(:first-child){
      margin-left: 100px;

      @media(max-width: 991px){
        margin-left: 60px;
      }

      @media(max-width: 767px){
        margin-left: 0px;
        margin-top: 60px;
      }
    }

    &-text{
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 30px;

      @media(max-width: 575px){
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #C4C4C4;
        margin-top: 10px;
      }
    }

    img{
      width: 100%;
    }
  }
}

.mobile{
  margin-top: 120px;

  @media(max-width: 992px){
    margin-top: 90px;
  }

  @media(max-width: 575px){
    margin-top: 45px;
  }

  &__inner{
    margin-top: 120px;
    padding-top: 60px;
    padding-bottom: 60px;

    @media(max-width: 991px){
      margin-top: 100px;
      padding-top: 52px;
      padding-bottom: 52px;
    }

    @media(max-width: 575px){
      margin-top: 45px;
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

  &__title{
    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }

    @media(min-width: 992px){
      &-label{
        flex: 0 1 502px;
        padding-right: 60px;
        margin-left: 100px;
      }
    }

    &-main{
      flex: 0 1 502px;
      h2{
        @extend %third-title;
        font-weight: bold;
        color: #2E3139;
      }
    }

    &-text{
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 20px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px){
        padding-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-subtext{
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 20px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px){
        padding-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__body{
    display: flex;
    margin-top: 120px;
    @media(max-width: 991px){
      margin-top: 100px;
    }
    @media(max-width: 575px){
      margin-top: 45px;
    }

    img{
      max-width: 638px;
      width: 100%;
      height: auto;
    }
  }
}

.tablet{
  margin-top: 120px;
  background-color: rgba(37, 55, 70, 1);
  padding: 120px 0px;

  @media(max-width: 991px){
    margin-top: 100px;
    padding: 100px 0px;
  }

  @media(max-width: 575px){
    margin-top: 45px;
    padding: 45px 0px;
  }

  &__title{
    @media(min-width: 992px){
      display: flex;
      justify-content: space-between;
    }

    &-main{
      flex: 0 1 502px;
      h2{
        @extend %third-title;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    &-text{
      flex: 0 1 502px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      padding-right: 120px;
      margin-left: 100px;

      @media(min-width: 992px){
        padding-top: 20px;
      }

      @media(max-width: 1199px) {
        flex: 0 1 485px;
        padding-right: 20px;
        margin-left: 88px;
      }

      @media(max-width: 992px){
        margin-left: 0px;
        margin-top: 20px;
      }

      @media(max-width: 575px){
        margin-top: 10px;
      }
    }
  }

  &__body{
    margin-top: 120px;

    @media(max-width: 991px) {
      margin-top: 100px;
    }

    @media(max-width: 575px){
      margin-top: 45px;
    }

    img{
      max-width: 612px;
      width: 100%;
      height: auto;
    }
  }
}

.comparison {
  background-color: #253746;
  margin-top: 114px;
  padding-top: 120px;
  padding-bottom: 120px;
  @media(max-width: 991px){
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media(max-width: 575px){
    margin-top: 45px;
    padding-top: 55px;
    padding-bottom: 55px;
  }
  @media(min-width: 991px){
    &__title{
      display: flex;
      justify-content: space-between;
      &-text{
        margin-left: 100px;
      }
    }
  }
  &__title {
    color: #FFFFFF;
    &-main {
      flex: 0 1 502px;
      @extend %third-title;
    }
    &-text{
      flex: 0 1 560px;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      padding-right: 150px;
      margin-top: 20px;
      @media(max-width: 1199px){
        flex: 0 1 560px;
        padding-right: 70px;
      }
      @media(max-width: 991px){
        margin-top: 15px;
        padding-right: 20px;
      }
      @media(max-width: 575px){
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        padding-right: 0px;
      }
    }
  }

  &__body {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 120px;

    @media(max-width: 991px){
      margin-top: 90px;
    }
    @media(max-width: 575px){
      margin-top: 35px;
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  &__image {
    @media(max-width: 575px){
      padding-right: 15px;
      padding-left: 15px;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  &__image:nth-child(2){
    margin-top: 246px;
    margin-left: 60px;

    @media(max-width: 1399px){
      margin-top: 200px;
    }
    @media(max-width: 1199px){
      margin-top: 146px;
    }
    @media(max-width: 991px){
      margin-top: 90px;
    }
  }

  .swiper{
    width: 100%;
    padding-right: 120px;
    padding-left: 100px;

    @media(max-width: 450px){
      padding-right: 70px;
      padding-left: 50px;
    }
    @media(max-width: 370px){
      padding-right: 50px;
      padding-left: 40px;
    }
  }
}

.conclusion{
  margin-top: 120px;

  @media (max-width: 991px) {
    margin-top: 90px;
  }

  @media (max-width: 575px) {
    margin-top: 45px;
  }

  &__title{
    @extend %third-title;
    font-weight: bold;
    color: #2E3139;
  }

  &__text{
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 606px;
    margin-top: 30px;

    @media(max-width: 992px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.view-more{
  margin-bottom: 60px;

  @media(max-width: 991px){
    margin-top: 90px;
    margin-bottom: 130px;
  }

  @media(max-width: 575px){
    margin-top: 45px;
    margin-bottom: 96px;
  }

  &__title{
    @extend %third-title;
    font-weight: bold;
    color: #2E3139;
  }

  &__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 575px){
      justify-content: center;
    }
  }

  &__btn{
    display: flex;
    justify-content: center;
  }

  &__button{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    background-color: rgba(46, 49, 57, 1);
    border: 1px solid rgba(46, 49, 57, 1);

    svg > path{
      fill: #fff;
    }

    @media (any-hover: hover) {
      &:hover  {
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;

        svg > path{
          fill: rgba(46, 49, 57, 1);
        }
      }
    }

    &:active{
      color: rgba(46, 49, 57, 1) !important;
      border: 1px solid rgba(46, 49, 57, 1);
      background: #fff;

      svg > path{
        fill: rgba(46, 49, 57, 1);
      }
    }
    @media(max-width: 991px){
      display: none;
    }
  }

  &__button-mobile{
    display: none !important;
  }

  @media(max-width: 991px){
    &__button-mobile {
      display: inline-flex !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      background-color: rgba(46, 49, 57, 1);
      border: 1px solid rgba(46, 49, 57, 1);
      margin-top: 45px;

      svg > path {
        fill: #fff;
      }

      @media (any-hover: hover) {
        &:hover {
          color: rgba(46, 49, 57, 1) !important;
          background: #fff;

          svg > path {
            fill: rgba(46, 49, 57, 1);
          }
        }
      }

      &:active{
        color: rgba(46, 49, 57, 1) !important;
        border: 1px solid rgba(46, 49, 57, 1);
        background: #fff;

        svg > path{
          fill: rgba(46, 49, 57, 1);
        }
      }
    }
  }


  &__body{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 45px;

    @media(max-width: 991px){
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
    }

    @media(max-width: 767px){
      grid-template-columns: 1fr;
      row-gap: 60px;
    }
  }
}


</style>
