<template>
  <div class="view-more__item">
    <div class="tabs__item" @click="$router.push(`${pathToCase}`)">
      <div class="tabs__image">
        <picture>
          <source :srcset="projectImageWebp" type="image/webp">
          <img
              class="view-more__item-image"
              :src="projectImagePng"
              alt=""
              loading="lazy"
          />
        </picture>
      </div>

      <div class="tabs__body">
        <div class="tabs__title">{{ $t(projectTitle) }}</div>
        <div class="tabs__text">{{ $t(projectDescription) }}</div>
        <router-link class="tabs__button"
                     aria-current="page"
                     :to="`${pathToCase}`">
          {{ $t('main_page.portfolio_section.read_more') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RecentProject",
  props: {
    projectImageWebp: {
      type: String,
    },
    projectImagePng: {
      type: String,
    },
    projectTitle: {
      type: String,
    },
    projectDescription: {
      type: String,
    },
    projectText: {
      type: String,
    },
    pathToCase: {
      type: String
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

@import "../base/styles/media";

.tabs__item {
  color: #2E3139;
  padding-top: 160px;
  position: relative;
  border: 1px solid #F2F2F2;

  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3675AF;
    transition: 0.6s color;
    @media(max-width: 575px){
      font-size: 12px;
      line-height: 18px;
    }
  }

  span:not(:first-child){
    margin-left: 10px;
  }
  a {
    transition: 0.4s color;
  }

  a:hover {
    color: #688E93;
  }
}

.tabs__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  z-index: -4;
  transition: 0.5s all;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.tabs__title{
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2E3139;
  margin-bottom: 10px;
  transition: 0.6s color;
}

.tabs__text{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #454C4F;
  margin-top: 10px;
  transition: 0.6s color;
}

.tabs__button{
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #3675AF;
  text-decoration: underline;
  margin-top: 20px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.tabs__body{
  padding:15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media(max-width: 575px){
    padding: 10px 10px 30px 10px;
  }
}

@media (any-hover: hover) {
  .tabs__item:hover .tabs__image{
    height: 100%;
    filter: brightness(50%);
  }
  .tabs__item:hover .tabs__title{
    color: #FFF;
  }
  .tabs__item:hover .tabs__button{
    color: #fff;
  }

  .tabs__item:hover .tabs__text{
    color: #FFF;
  }
  .tabs__item:hover span{
    color: #FFF;
  }
}

.view-more {


  &__item {
    height: 100%;
    position: relative;

    @media(max-width: 575px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    &-main {
      padding: 40px 60px 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);;

      @media(max-width: 1399px) {
        padding: 40px;
      }

      @media(max-width: 1199px) {
        padding: 30px;
      }

      @media(max-width: 575px) {
        padding: 30px 64px 30px 15px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #FFFFFF;
      flex: 1 1 auto;

      @media(max-width: 575px) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      flex: 1 1 auto;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 10px;
      margin-bottom: 54px;
      flex: 1 1 auto;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-btn {
      border: 2px solid #fff !important;
      margin-top: 48px;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
}

@media (any-hover: hover) {
  .view-more__item {
    cursor: pointer;
  }
}
</style>
