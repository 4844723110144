<template>
  <section id="sec4">
    <div class="container">
      <div class="project">
        <div class="project__title">
          <div>
            <h2 class="project__title-text">{{ $t('main_page.portfolio_section.title') }}</h2>
          </div>
          <div class="project__title-description">{{ $t('main_page.portfolio_section.description') }}</div>

        </div>
        <div class="project__list">
            <RecentProject
                v-for="project in projectList"
                :key="project.id"
                :projectImageWebp="project.projectImageWebp"
                :projectImagePng="project.projectImagePng"
                :projectTitle="project.projectTitle"
                :projectDescription="project.projectDescription"
                :pathToCase="project.pathToCase"
            />
        </div>
        <div class="project__btn" v-for="link in recentCases" :key="link.id">
          <router-link class="project__title-mobile btn__general" aria-current="page" :to="link.path">{{ $t('main_page.portfolio_section.button') }}
            <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="#2E3139"/>
            </svg>
          </router-link >
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import RecentProject from "./RecentProject";

export default {
  name: "RecentProjects",
  data() {
    return {
      projectList: [
        {
          id: 1,
          projectImagePng: require("../assets/ukrpol_image_presentation.png"),
          projectImageWebp: require("../assets/ukrpol_image_presentation.webp"),
          projectTitle: "main_page.portfolio_section.recent_project.ukrpol.projectTitle",
          projectDescription: "main_page.portfolio_section.recent_project.ukrpol.projectDescription",
          pathToCase: '/cases/ukrpol_ua/'
        },
        {
          id: 2,
          projectImagePng: require("../assets/hackenproof_image_presentation.png"),
          projectImageWebp: require("../assets/hackenproof_image_presentation.webp"),
          projectTitle: "cases_page.cases_prevent.hackenproof.projectTitle",
          projectDescription: "cases_page.cases_prevent.hackenproof.projectText",
          pathToCase: '/cases/hackenproof/'
        },
        {
          id: 3,
          projectImagePng: require("../assets/oversecured_image_presentation.png"),
          projectImageWebp: require("../assets/oversecured_image_presentation.webp"),
          projectTitle: "main_page.portfolio_section.recent_project.oversecured.projectTitle",
          projectDescription: "main_page.portfolio_section.recent_project.oversecured.projectDescription",
          pathToCase: '/cases/oversecured/'
        },
      ],
      recentCases: [
        {id: 1, path: '/cases/'}
      ]
    }
  },
  components: {
    RecentProject
  }
}
</script>

<style lang="scss">


.view-more__item {
  img{
    object-position: 0px 0px;
  }
}

@media(max-width: 767px){
  .view-more__item:nth-child(1) {
    img{
      object-position: 0px -150px;
    }
  }
}

@media(max-width: 575px){
  .view-more__item:nth-child(1) {
    img{
      object-position: 0px -80px;
    }
  }
}

.project {
  position: relative;
  padding-top: 120px;

  @media(max-width: 991px){
    padding-top: 70px;
  }

  @media(max-width: 575px){
    padding-top: 45px;
  }
  &__title-btn{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff !important;
    background-color: rgba(46, 49, 57, 1);
    border: 1px solid rgba(46, 49, 57, 1);

    svg > path{
      fill: #fff;
    }

    @media (any-hover: hover) {
      &:hover  {
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;

        svg > path{
          fill: rgba(46, 49, 57, 1);
        }
      }
    }

    &:active{
      color: rgba(46, 49, 57, 1) !important;
      border: 1px solid rgba(46, 49, 57, 1);
      background: #fff;

      svg > path{
        fill: rgba(46, 49, 57, 1);
      }
    }
  }
  &__title{
    @media(min-width: 992px){
      display: flex;
      flex-direction: column;
    }

    &-mobile {
      display: inline-flex !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(46, 49, 57, 1)!important;
      border: 1px solid rgba(46, 49, 57, 1);
      margin-top: 60px;
      @media(max-width: 575px){
        margin-top: 45px;
      }
      svg > path{
        fill: rgba(46, 49, 57, 1);
      }

      @media (any-hover: hover) {
        &:hover  {
          color: #fff !important;
          background: rgba(46, 49, 57, 1) !important;

          svg > path{
            fill: #fff;
          }
        }
      }

      &:active{
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;
        border: 2px solid rgba(46, 49, 57, 1);

        svg > path{
          fill: rgba(46, 49, 57, 1);
        }
      }
    }

    @media(max-width: 991px){
      &-mobile{
        display: inline-flex !important;
        color: #fff !important;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        background-color: rgba(46, 49, 57, 1) !important;
        border: 1px solid rgba(46, 49, 57, 1);

        svg > path{
          fill: #fff;
        }

        @media (any-hover: hover) {
          &:hover  {
            color: rgba(46, 49, 57, 1) !important;
            background: #fff;

            svg > path{
              fill: rgba(46, 49, 57, 1);
            }
          }
        }

        &:active{
          color: rgba(46, 49, 57, 1) !important;
          background: #fff;
          border: 2px solid rgba(46, 49, 57, 1);

          svg > path{
            fill: rgba(46, 49, 57, 1);
          }
        }
      }
    }
  }

  &__title-text {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #2E3139;

    @media(max-width: 991px){
      font-size: 32px;
      line-height: 48px;
    }

    @media(max-width: 575px){
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }
  &__title-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 585px;
    margin-top: 30px;

    @media(max-width: 991px){
      font-size: 16px;
      line-height: 44px;
    }

    @media(max-width: 575px){
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.project__list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 95px;
  row-gap: 20px;
  margin-top: 60px;

  @media(max-width: 991px){
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
  }

  @media(max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media(max-width: 575px){
    margin-bottom: 20px;
    margin-top: 45px;
    row-gap: 60px;
  }
}

.project__body {
  &-logo {
    width: 100%;
  }

  &-desc {
    padding: 10px 0 35px;
    font-size: 14px;
    line-height: 22px;
    color: #2E3139
  }

  @media(max-width: 767px){
    flex: 1 1 100%;
  }

  @media(min-width: 768px){
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}

.btn-case {
  margin-top: 0
}

.company-logo {
  max-width: 400px;
  height: 172.5px;
}

.project-logo {
  margin: 0 10px;
}

.btn-next {
  font-size: 20px;
  line-height: 28px;
  position: absolute;
  top: 200px;
  right: 0;
  border: 1px solid black;
  padding: 10px 60px 10px 20px;
}
</style>
